.App {
  text-align: center;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #c59131;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text_size, .text_size_dph{
  
  margin-right:2%;
  padding-left: 1%;
  padding-right: 1%;
  
}

.small_text{
  font-size: 0.9em;
  margin: -1% 3% 3% 0;
}


.app {
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-top: 1%;
  width:100%;
 
}

.navBar{
  display: none;
}


h2{
  font-size: 1.2em;
  font-weight: bold;
}

.h2_app{
margin-left: 0%;
}

.h2_price{
  margin-top:5%;
  margin-left: 25%;
  font-size: 1.5em;
  }

.bold{
  font-weight: bold;
  line-height: 2em;
}

#root{
  margin: auto 10%;
}

.intro{
  margin-left:0.7%
}

.form_borders{
  margin-left:10%;
  
}

.bill_month{
  margin-top:1% ;
}

.inputs{
  margin-right: 3%;
  margin-bottom: 5%;
}

.border_orange{
  box-sizing: border-box;
  max-width: 90%;
  background: rgb(243, 224, 188);
  box-shadow: 10px 10px 1000px 0 #333333;
  mix-blend-mode: normal;
  backdrop-filter: blur(1%);
  border: darkcyan solid 1px;
  margin:3% 0;
  padding-left: 1%
}

.border_light_orange{
  box-sizing: border-box;
  max-width: 90%;
  background: #f9f0de;
  mix-blend-mode: normal;
  backdrop-filter: blur(1%);
  border: darkcyan solid 1px;
  padding: 0 1% 1% 1%;
  Margin-top:3%;
  
}

.border_white{
  box-sizing: border-box;
  mix-blend-mode: normal;
  backdrop-filter: blur(1%);
  padding: 0 2% 1% 1%;
  Padding-top:1%
}

.pay{
  margin-right: 1%;
}

#close{
  border: none;
  background-color: #f9f0de;
  position: relative;
  margin: 1% 0 0 95%
}

#h2_vat{
  margin-top: -2%;
}




@media only screen and (max-width: 1300px){
  .border_orange, .border_light_orange{
    max-width: 90%;
    padding-bottom: 2%;
    padding-left: 2%;
  }
 

.bill_month{
  margin-top:3% ;
}


@media only screen and (max-width: 950px){
.form_borders{
  margin-left:1%;
}

.border_orange, .border_light_orange {
  max-width: 100%;
}

}

@media only screen and (max-width: 820px){
  .intro{
    margin-left: 2.5%;
  }
}

@media only screen and (max-width: 700px){
  body {
    font-size: 1em;
}
}

@media only screen and (max-width: 600px){
  .text_size_dph {
    padding-bottom: 2%;
}
.bill_month, .inputs {
  width: 20% ;
}
.small_text{
  margin-bottom: 5%;
}


.navBar {
  display: block;
  position: relative;
 
}

.navBar button {
  background-color: darkcyan;
  border: none;
  position: absolute;
  left: 85%;
  top: 30%;
  font-size: 2.3em;
  cursor: pointer;
  z-index: 6;
}

.menuNav {
  overflow-y: hidden;
  list-style: none;
  background: darkcyan;
  margin-top:-1%;
  height: 9vh; 
  max-width: 100%;
  z-index: 9;
}

.menuNav.showMenu {
  overflow:visible;
  margin-top: -24%;
  height: auto; /*15vh; */
  display: block; 
  position: relative;
  z-index: 5;
}

}

.navBar a {
  display: block;
  padding-top:1%;
  text-decoration: none;
  color:white;
  font-size: 0.8em;
  text-transform: uppercase;
  text-align: right;
  margin-right: 20%;
  cursor: pointer;
  line-height: 1.5em;
}

.kontakt{
  margin-right: -5%;
}

a:hover{
  font-weight: bold;
  cursor: pointer;
}

.menuNav li:first-child {
  margin-top: 9rem;
}

.menuNav li:last-child {
  background-color: darkcyan; 
  margin-left: -17%;
  padding-bottom: 0.5rem; 
}


#close {
  margin-left: 90%
}

}

@media only screen and (max-width: 550px){
  .menuNav.showMenu {
    margin-top: -27%;
  }
}


@media only screen and (max-width: 540px){
  .radios_btn > span{
    white-space:nowrap;
  }
  .intro{
    margin-right: 5%;
  }
}

@media only screen and (max-width: 500px){
 
  .menuNav.showMenu {
    margin-top: -23%;
   }
 
  .text_size_dph, .text_size{
    font-size: 0.8em;
    padding-right: 3%;
  }

  .bill_month{
    margin-bottom: 5%;
  }

  .pay{
  font-size: 0.9em;
  }
  .h2_app{
    font-size: 1.1em;
  }

  .intro{
    margin-left: 4%;
  }

}

@media only screen and (max-width: 480px){
  .menuNav.showMenu {
    margin-top: -30%;
  }
  .menuNav li:first-child {
     padding-top: 1rem;
}

}

@media only screen and (max-width: 470px){
  .menuNav.showMenu {
    margin-top: -32%;
  }
}

@media only screen and (max-width: 450px){
  .intro {
    margin-left: 0%;
  }
}

@media only screen and (max-width: 440px){
  .menuNav.showMenu {
    margin-top: -34%;
  }

  .menuNav li:first-child {
    margin-top: 9.2rem;
}
}

@media only screen and (max-width: 420px){
  .menuNav.showMenu {
    margin-top: -36%;
  }
 
}


@media only screen and (max-width: 415px){
 
  .menuNav {
    height: 11vh;
}
}


@media only screen and (max-width: 400px){
  .menuNav.showMenu {
    margin-top: -38%;
  }

}

@media only screen and (max-width: 395px){
  .menuNav.showMenu{
  margin-bottom:1.5vh
  }
  
}

@media only screen and (max-width: 380px){
  .menuNav.showMenu {
    margin-top: -39%;
  }

  .navBar a {
    padding-top:0%;}
}

@media only screen and (max-width: 370px){
  .menuNav.showMenu {
    margin-top: -41%;
  }

  .kontakt{
    margin-right: -6%;
  }

}


@media only screen and (max-width: 365px){
 
  #dph{
    margin-bottom: 8%;
  }

  .bill_month, .inputs {
    width: 50% ;
  }

  .border_light_orange,.border_orange{
    background-color: rgb(243, 224, 188);
    border-radius: 0em;
    padding: 2% 5% 2% 10%;
    margin-top: 5%; 
  }

  .form_borders {
    margin-left: 0;
}

.border_white{
  margin: 3% 9%
}

#close {
 background-color: rgb(243, 224, 188);
}

}

@media only screen and (max-width: 350px){
  .menuNav.showMenu {
    margin-top: -43.5%;
  }

  .navBar button {
    left: 80%;
}

.navBar a{
  margin-right: 25%;
}

}

@media only screen and (max-width: 330px){
  .border_light_orange,.border_orange{
    padding-left: 5%;
  }

  .border_white{
    margin: 3% 5%
  }

  .h2_price{
    margin: 15% 0 10% 25%;
  }

  .menuNav.showMenu {
    margin-top: -46%;
  }

}

@media only screen and (max-width: 320px){
  .intro{
    margin:0 0% 15% -1%
  }

  .menuNav {
    height: 12vh;
}

.navBar button{
  font-size: 2em;
}


.menuNav a {
  font-size: 0.7rem;
  margin-top: -1%;
}

.text_size{
  padding-left: 2%;
}
}

@media only screen and (max-width: 310px){
  .menuNav.showMenu {
    margin-top: -49%;
    margin-bottom: 5%;
  }

}



@media only screen and (max-width: 300px){
  #root{
    margin-left: -0.5%;
  }


  .border_light_orange, .border_orange{
    border: none;
    box-sizing: inherit;
    padding-left: 16% ;   
}

.pay, 
.small_text,
.h2_app,
.ucto_p
{
  margin-right: 12%;
  margin-left: -5%;
}

.intro{
  margin-left: 13%;
  margin-right: -5%;
}

.border_white{
  margin: 2% 15%
}

.form_borders{
  width: 111%;
}

}

@media only screen and (max-width: 290px){

.intro{
  margin-right: -8%;
}

.pay, 
.small_text{
  margin-right: 10%;
}

.border_light_orange, .border_orange {
  padding-left: 16%;
}

.menuNav.showMenu {
  margin-top: -51%;
}
}



@media only screen and (max-width: 290px){

  .intro{
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 280px){
  .menuNav.showMenu {
    margin-top: -53%;
  }

}

@media only screen and (max-width: 270px){
  .menuNav.showMenu {
    margin-top: -55%;
  }

}


@media only screen and (max-width: 260px){
.border_light_orange > span{
  white-space:nowrap;
}

.menuNav.showMenu {
  margin-top: -57%;
}

.navBar button {
  left: 70%;
}

.navBar a{
margin-right: 45%;
}

.kontakt{
  margin-right: -15%;
}

}

@media only screen and (max-width: 240px){
    
  .menuNav.showMenu {
    margin-top: -65%;
  }

  .menuNav{
    height: 18vh;
    margin-bottom: 3vh;
  }

  

}